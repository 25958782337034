<template>
  <div>
    <div class="card p-2">
      <TitleButton
        :showBtn="true"
        title="Money Receipt"
        :showAddNew="false"
        @onClickCloseButton="navigateToListPage"
      />

      <div class="row gy-1 pt-75 bg-blue-light rounded-8 mt-1 pb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">

          <div class="d-flex align-items-center justify-content-between filter-label">
              <label for="colFormLabel" class="col-form-label">Party: </label>
              <span v-if="!routeContactId" class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
          </div>
          <input
              v-if="routeContactId"
              type="text"
              class="form-control invoice-edit-input"
              placeholder=""
              v-model="singleContactInfo.name"
              readonly
          />

          <AsyncSelect
              v-else
              placeholder="Party Name, ID, Mobile No"
              v-model="selectedContact"
              :api-service="fetchContactProfiles"
              :additional-query="{ with_mobile: 1, roles: 'customer' }"
              :format-label="formatPatientLabel"
              :option-selected="onchangeContact"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received Date</label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            placeholder=""
            v-model="salesFormData.receipt_date"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Voucher No</label>
          <div class="input-group input-group-merge invoice-edit-input-group">
            <div class="input-group-text">
              <span>{{ prefix }}-</span>
            </div>
            <input
              type="number"
              min="1"
              class="form-control invoice-edit-input"
              placeholder=""
              v-model="serial"
            />
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received In</label>
          <v-select
            v-model="salesFormData.account_head_id"
            placeholder="Select Cash & Bank Account"
            class="w-100"
            :options="accountHeads"
            label="name"
            :reduce="(name) => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received Ref</label>
          <input
            type="hidden"
            class="form-control invoice-edit-input date-picker flatpickr-input"
            readonly="readonly"
          />
          <div class="input-group input-group-merge invoice-edit-input-group">
            <div class="input-group-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-hash"
              >
                <line x1="4" y1="9" x2="20" y2="9"></line>
                <line x1="4" y1="15" x2="20" y2="15"></line>
                <line x1="10" y1="3" x2="8" y2="21"></line>
                <line x1="16" y1="3" x2="14" y2="21"></line>
              </svg>
            </div>
            <input
              v-model="salesFormData.receipt_reference"
              type="text"
              class="form-control invoice-edit-input"
              placeholder=""
            />
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">R.Ref Date</label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            placeholder=""
            v-model="salesFormData.receipt_reference_date"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Balance</label>
          <input
            type="hidden"
            class="form-control invoice-edit-input date-picker flatpickr-input"
            readonly="readonly"
          />
          <div class="input-group input-group-merge invoice-edit-input-group">
            <input
              v-model="balance"
              readonly
              type="text"
              class="form-control invoice-edit-input"
              placeholder=""
            />
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Admission info</label>
          <v-select
              v-model="salesFormData.ipd_register_id"
              placeholder="Select Admission Info"
              class="w-100"
              :options="ipdRegisters"
              label="text"
              :reduce="text => text.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Advance Amount</label>
          <div class="input-group">
            <input
              v-model="salesFormData.total_paid_amount"
              type="number"
              class="form-control"
              placeholder=""
            />
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">VDS Account</label>
          <v-select
              v-model="salesFormData.vat_challan_account_head_id"
              placeholder="Select VAT Challan Receivable"
              class="w-100"
              :options="vatChallanHeads"
              label="name"
              :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">AIT Accounts</label>
          <v-select
              v-model="salesFormData.ait_challan_account_head_id"
              placeholder="Select AIT Challan Receivable"
              class="w-100"
              :options="aitChallanHeads"
              label="name"
              :reduce="(name) => name.id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Discount Account</label>
          <v-select
              v-model="salesFormData.discount_account_head_id"
              placeholder="Select Discount Account"
              class="w-100"
              :options="discountAccountHeads"
              label="name"
              :reduce="(name) => name.id"
          />
        </div>
      </div>

      <div class="mt-2"></div>
      <BillPayTable @updateTotal="updateTotalAmount" :payBills="invoiceDueBills" />

      <div class="row mt-1">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea
              v-model="salesFormData.description"
              placeholder="Memo"
              class="form-control"
              rows="2"
              id="note"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex gap-1">
        <button
          :disabled="saveButtonLoader"
          @click="payBill(true, true)"
          class="btn btn-primary"
        >
          <!-- <div v-if="saveButtonLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div> -->
          Save
        </button>
        <button
          :disabled="saveButtonLoader"
          @click="payBill(false,true)"
          class="btn btn-primary"
        >
          <!-- <div v-if="saveButtonLoader" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div> -->
          Save & New
        </button>
        <button
          @click="navigateToListPage"
          class="btn btn-outline-secondary cancel-btn"
        >
          Cancel
        </button>
      </div>
    </div>
    <GlobalLoader />
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="customer"
    />
  </div>
</template>

<script setup>
import {ref, onMounted, inject, computed} from "vue";
import { useRouter, useRoute } from "vue-router";
import TitleButton from "@/components/atom/TitleButton";
import BillPayTable from "@/components/molecule/company/inventory/sales/BillPayTable";
import useBillPayData from "@/data/inventory/billPay.js";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";

import handlePurchaseAndSales from "@/services/modules/purchase";
import {generateTxnNumber}    from "@/services/utils/voucherNumberGenerator";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import handlePurchase from "@/services/modules/purchase";
import handleCompany from "@/services/modules/company";
import moneyReceiptHelper from "@/services/utils/pdf/moneyReceiptHelper";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import { useStore } from 'vuex'
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";

const { salesFormData, storeInvoice, storeAdvanceInvoice, prepareSalesFormData, fetchMoneyReceipt } =
  handlePurchaseAndSales();
const route = useRoute();
const router = useRouter();
const store = useStore();
const voucherQuery = `?company_id=${route.params.companyId}&voucher_type=receipt_voucher&txn_type=invoice_receipt`;
const {
  getInvoiceDueList,
  getAccountHead,
  getContactProfiles,
  voucher_query,
  accountHeads,
  business,
  contacts,
  balance,
  prefix,
  serial,
  invoiceDueBills,
  params,
} = useBillPayData(voucherQuery);

let loader =ref(false)
const saveButtonLoader = ref(false);
const showError = inject("showError");
const showSuccess = inject("showSuccess");
const ipdRegisters = ref([]);
const accountReceivables = ref([]);
const vatChallanHeads = ref([]);
const aitChallanHeads = ref([]);
const discountAccountHeads = ref([]);
const selectedContact = ref(null);
const selectedSaleMasterId = ref(null);
const {fetchContactProfiles, fetchSingleContactProfile} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper()
const { fetchIpdRegisterList } = handleHospitalBilling()
const {fetchAccountReceivable, getAccountHeadBySlag, fetchSalesBill} = handlePurchase()
const {fetchCompanyInfo} = handleCompany();
const {generatePdf} = moneyReceiptHelper();
const {generatePdf:saleInvoicePdf} = hospitalPdfPrinterHelper();

const companyId = computed(() => route.params.companyId);
const routeContactId = computed(() => route.query.contactId ? route.query.contactId : '')
const routeSaleMasterId = computed(() => route.query.saleMasterId ? route.query.saleMasterId : '')

const totalAmount = ref(0);
const moneyReceipt = ref({});
const companyInfo = ref({});
const invoiceRes = ref({});
const singleContactInfo = ref({});

const updateTotalAmount = (newTotal) => {
  totalAmount.value = newTotal;
};

const getAccountReceivable = () => {
  fetchAccountReceivable(`?company_id=${companyId.value}`).then(({status, data}) => {
        if (status) accountReceivables.value = data
        if (data.length < 1) {
          return salesFormData.value.account_receivable_id = null;
        }

        salesFormData.value.account_receivable_id = data[0].id
      })
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
  selectedContact.value = contact;
  getInvoiceDueList(contact.id);
  getIPDRegisterList(contact.id);
}

onMounted(async () => {
  const companyQuery = `?company_id=${companyId.value}`;
  if(route.query.contactId) {
    fetchSingleContactProfile(route.query.contactId, companyQuery).then((res) => {
      singleContactInfo.value = res.data;
    }).then(() => {
      onchangeContact(singleContactInfo.value)
    }).catch(e => console.log('Something went wrong'));
  }
  let voucher = await generateTxnNumber(voucher_query);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  getAccountHead();
  getAccountReceivable();
  let data = salesFormData.value;
  data.receipt_date = new Date().toISOString().split("T")[0];
  data.company_id = params.companyId;
  getCompanyInfo();

  const vatChallanHeadsRes = getAccountHeadBySlag("vat_challan", companyQuery)
  const aitChallanHeadsRes = getAccountHeadBySlag("ait_challan", companyQuery)
  const discountAccountHeadsRes = getAccountHeadBySlag("discount_account", companyQuery)
  Promise.all([
    vatChallanHeadsRes.then(({data}) => {
      if (data) vatChallanHeads.value = data
      if (data.length < 1) {
        return salesFormData.value.vat_challan_account_head_id = null;
      }

      salesFormData.value.vat_challan_account_head_id = data[0].id
    }),
    aitChallanHeadsRes.then(({data}) => {
      if (data) aitChallanHeads.value = data
      if (data.length < 1) {
        return salesFormData.value.ait_challan_account_head_id = null;
      }

      salesFormData.value.ait_challan_account_head_id = data[0].id
    }),
    discountAccountHeadsRes.then(({data}) => {
      if (data) discountAccountHeads.value = data
      if (data.length < 1) {
        return salesFormData.value.discount_account_head_id = null;
      }

      salesFormData.value.discount_account_head_id = data[0].id
    })
  ]).then(() => {
    loader.value = false
  }).catch(() => {
    loader.value = false
  })
});
function navigateToListPage() {
  if(route.query.type === 'pending-money-receipt'){
    router.push({
      name: "pending-money-receipt",
      params: params,
      query: route.query,
    });
    return;
  }
  if(route.query.contactId){
    router.push({
      name: "patient-register",
      params: params,
      query: route.query,
    });
    return;
  }
  router.push({
    name: "money-receipt-list",
    params: params,
    query: route.query,
  });
}
function onchangeContact(e) {
  selectedSaleMasterId.value = routeSaleMasterId.value
  getInvoiceDueList(e.id,true,routeSaleMasterId.value);
  getIPDRegisterList(e.id);
}

function goToBack () {
  router.back();
}

const getIPDRegisterList = (id) => {
  const companyQuery = `?company_id=${companyId.value}`;
  fetchIpdRegisterList(`${companyQuery}&contact_profile_id=${id}`).then(res => {
    if (!res.status) {
      salesFormData.value.ipd_register_id = ''
      return ipdRegisters.value = [];
    }
    ipdRegisters.value = res.data;
  })
}
const storeAdvance = (redirect = false, print = false) => {
  storeAdvanceInvoice({ ...salesFormData.value, date: salesFormData.value.receipt_date})
      .then((res) => {
        saveButtonLoader.value = false;
        if (res.status) {
          showSuccess(res.message);
          goToPrint(res.data.id)
          if (redirect) {
            goToBack();
          }
        }
        if (!res.status) {
          showError(res.message);
        }
      })
      .catch((err) => {
        saveButtonLoader.value = false;
      });
}

const getCompanyInfo = async() => {
  try {
    const res = await fetchCompanyInfo(companyId.value);

    if(! res.status) {
      showError('Something is wrong. Check your connectivity!!');
      return;
    }

    companyInfo.value = res.data;
  } catch (err) {
    showError('Something is wrong')
  }
}

function payBill(redirect = false, print = false) {
  saveButtonLoader.value = true;
  salesFormData.value.contact_profile_id = routeContactId.value ? routeContactId : selectedContact.value.id;
  salesFormData.value.voucher_no = prefix.value + "-" + serial.value;
  if (totalAmount.value === 0){
    return storeAdvance(redirect, true)
  }
  const payload = prepareSalesFormData(invoiceDueBills.value, "sale");
  storeInvoice(payload)
    .then((res) => {
      saveButtonLoader.value = false;
      if (res.status) {
        showSuccess(res.message);
        getInvoiceDueList(selectedContact.value,true,selectedSaleMasterId.value);
        if (print) {
          goToPrint(res.data.id)
        }
        if (redirect) {
          goToBack();
        }
      }
      if (!res.status) {
        showError(res.message);
      }
    })
    .catch((err) => {
      saveButtonLoader.value = false;
    });
}

const saleInvoicePrint = (id) => {
  const companyQuery = `?company_id=${companyId.value}`;

  fetchSalesBill(id, companyQuery).then((res) => {
    if (res) {
      invoiceRes.value = res.data;
    }
  }).then(() => {
    saleInvoicePdf(companyInfo.value, invoiceRes.value, 'BILL', true, true, invoiceRes.value.invoice_type === "OPD Bill")
  }).catch((err) => {
    console.log(err);
  })
}

const moneyReceiptPrint = (id) => {
  const companyQuery = `?company_id=${companyId.value}`;

  fetchMoneyReceipt(id, companyQuery).then((res) => {
    if(! res.status) {
      return;
    }
    moneyReceipt.value = res.data;
  }).then(() => {
    generatePdf(companyInfo.value, moneyReceipt.value)
  }).catch(e => {
    showError('Something is wrong. Check your connectivity!!')
  })
}

const goToPrint = (id) => {
   if (routeSaleMasterId.value){
     saleInvoicePrint(routeSaleMasterId.value)
   }
   if (!routeSaleMasterId.value){
      moneyReceiptPrint(id)
   }
}
</script>

<style scoped>
.filter-label {
    padding-right: 0.5rem !important;
}
</style>
